@import "@/style/index.scss";

.step {
  @include d-flex-column;
  gap: 2rem;
  @media screen and (min-width: $desktop) {
    @include d-flex;
    gap: 5rem;
  }
  &__img {
    width: 60%;
    @media screen and (min-width: $desktop) {
      width: auto;
    }
  }
  &__content {
    @include d-flex-column;
    gap: 2vh;
    align-items: flex-start;
    &__title {
      @include headerFont();
      font-size: 1.3rem;
      font-weight: 600;
      @media screen and (min-width: $desktop) {
        font-size: 2rem;
      }
    }
    &__description {
      @include textFont();
      @media screen and (min-width: $tablet) {
        width: 40rem;
      }
    }
  }
}

.stepR {
  @media screen and (min-width: $desktop) {
    flex-direction: row-reverse;
  }
}
