@import "@/style/index.scss";

.contentSection {
  width: 90%;
  margin: 0 auto;
  @include d-flex-column;
  &__h3 {
    @include small-headers;
  }
  &__h4 {
    @include below-headers;
  }
}
